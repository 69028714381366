import * as React from "react"
import Layout from "../../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"

import {
  Typography,
  Container
} from "@mui/material"

const BlogPost = ({ data }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <Container
        sx={{
          marginTop: "150px",
          marginBottom: "150px",
          color: "black",
          maxWidth: { xs: "500px", md: "850px" },
        }}
      >
                <Typography variant="h5" component="div" sx={{ color: "black",fontSize: { xs: "1rem", md: "1rem" }, }}>
          Author: {data.mdx.frontmatter.author}
        </Typography>
        <p>Publish data: {data.mdx.frontmatter.date}</p>
        <Typography
          variant="h2"
          sx={{
            color: "black",
            margin: "auto",
            textAlign: "center",
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "2rem", md: "2rem" },
          }}
        >
          {data.mdx.frontmatter.title}
        </Typography>

        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        author
      }
      body
    }
  }
`

export default BlogPost
